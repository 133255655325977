import React from 'react';

import { Button, ButtonPriority } from 'wix-ui-tpa/cssVars';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { ISearchResultsProps } from './SearchResults.types';
import { SearchBox } from '../SearchBox';
import { Spinner } from '../Spinner';
import { DemoContentNotification } from '../DemoContentNotification';
import { StatusLine } from '../StatusLine';
import { TabLine } from './TabLine';
import { FailedMessage } from '../ResultsStatus';
import { useRemount, useSearchEnvironment } from '../../hooks';

import { st, classes } from './SearchResults.st.css';
import { FacetsDialog, useFacetsDialog } from '../FacetsDialog';
import { Pagination } from '../Pagination';

export const SearchResults: React.FC<ISearchResultsProps> = ({
  searchResultsRef,
  apiErrorDetails,
  buildPageUrl,
  facets,
  failed,
  isLoading = false,
  isPaginationHidden,
  isStatusLineHidden,
  isSearchBarEnabled,
  resultsCount,
  totalCount,
  selectedDocumentType,
  selectedSortOption,
  onSortChange,
  visibleTabsWithTitles,
  searchClearLabel,
  searchInputLabel,
  onQueryChange,
  onQueryClear,
  onSubmit,
  searchPlaceholder,
  searchQuery,
  activeTabIndex,
  onTabChange,
  lastQuery,
  demoContentNotificationText,
  children,
  currentPage,
  totalPages,
  onPageChange,
  onFacetsFilterReset,
}) => {
  const { isMobile, isEditorX, isCssPerBreakpoint } = useEnvironment();
  const { isDemoContent } = useSearchEnvironment();
  const { t } = useTranslation();

  const [isFacetsDialogOpen, openFacetsDialog, closeFacetsDialog] =
    useFacetsDialog();

  // Workaround for SSR/CSR hydration issues
  const key = useRemount();

  const showDesktopFacets = !!facets && !failed && !isMobile;
  const showMobileFacets = !!facets && !failed && isMobile;

  return (
    <div
      key={key}
      ref={searchResultsRef}
      data-hook="search-results-root"
      className={st(classes.root, {
        fluid: isEditorX || isCssPerBreakpoint,
        mobileView: isMobile,
      })}
    >
      <SearchBox
        clearLabel={searchClearLabel}
        inputLabel={searchInputLabel}
        onChange={onQueryChange}
        onClear={onQueryClear}
        onSubmit={onSubmit}
        placeholder={searchPlaceholder}
        value={searchQuery}
        totalCount={totalCount}
      />
      <div data-hook="content-wrapper" className={classes.contentWrapper}>
        <div
          data-hook="content"
          className={st(classes.content, { loading: isLoading })}
        >
          {visibleTabsWithTitles.length > 1 && (
            <TabLine
              activeTabIndex={activeTabIndex}
              items={visibleTabsWithTitles}
              onTabClick={onTabChange}
            />
          )}

          <div className={classes.layout}>
            {showDesktopFacets && (
              <div className={classes.facets}>{facets}</div>
            )}
            <div className={classes.items}>
              {showMobileFacets && (
                <Button
                  data-hook="facets-filter-button"
                  className={classes.facetsButton}
                  priority={ButtonPriority.basicSecondary}
                  onClick={openFacetsDialog}
                  upgrade
                  fullWidth
                >
                  {t('searchResults.facets.mobile.openFilterButton.label')}
                </Button>
              )}
              {failed ? (
                <FailedMessage
                  isMobile={isMobile}
                  apiErrorDetails={apiErrorDetails}
                />
              ) : (
                !isStatusLineHidden && (
                  <StatusLine
                    totalCount={totalCount}
                    resultsCount={resultsCount}
                    facetsEnabled={!!facets}
                    searchQuery={lastQuery}
                    selectedSortOption={selectedSortOption}
                    selectedDocumentType={selectedDocumentType}
                    onSortChange={onSortChange}
                  />
                )
              )}

              {isDemoContent && (
                <DemoContentNotification text={demoContentNotificationText} />
              )}
              {children}

              {!isPaginationHidden && (
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  buildPageUrl={buildPageUrl}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </div>
        </div>
        {isLoading && <Spinner dataHook="spinner" />}
        {showMobileFacets && isFacetsDialogOpen && !failed && (
          <FacetsDialog
            onClose={closeFacetsDialog}
            onReset={() => onFacetsFilterReset()}
            isLoading={isLoading}
          >
            {facets}
          </FacetsDialog>
        )}
      </div>
    </div>
  );
};

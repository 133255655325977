import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';

import { InputDialog } from 'wix-ui-tpa/cssVars';
import { Spinner } from '../Spinner';

import { st, classes } from './FacetsDialog.st.css';

export interface FacetsDialogProps {
  isLoading: boolean;
  onClose(): void;
  onReset(): void;
}

export const FacetsDialog: React.FC<FacetsDialogProps> = ({
  isLoading,
  onClose,
  onReset,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div className={st(classes.root, { loading: isLoading })}>
      <InputDialog
        data-hook="facets-dialog"
        className={classes.facetsDialog}
        title={t('searchResults.facets.mobile.title')}
        isOpen
        onClose={onClose}
        primaryButton={{
          text: t('searchResults.facets.mobile.okButton.label'),
          onClick: onClose,
        }}
        secondaryButton={{
          text: t('searchResults.facets.mobile.resetButton.label'),
          onClick: onReset,
        }}
        fullscreen
        closeButtonAriaLabel={t('searchResults.aria-label.closeFilters')}
      >
        <div className={classes.facetsContainer}>{children}</div>
        {isLoading && <Spinner monochrome dataHook="facets-spinner" />}
      </InputDialog>
    </div>
  );
};
